<template>
  <div id="journeyListPage">
    <!-- Header -->
    <div class="flex">
      <div class="flex-1 text-3xl font-semibold">{{ channel }} Journeys</div>

      <div class="flex">
        <el-input prefix-icon="el-icon-search" size="medium" v-model="searchFilter" @input="onSearch" placeholder="Search Journey"> </el-input>
        &nbsp;&nbsp;
        <!-- <el-select @change="resetPaginationAndSearch" class="statusFilter" size="small" v-model="statusFilter" multiple collapse-tags placeholder="Journey Status">
          <el-option v-for="item in filterOptions.status" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select> -->

        <el-button size="medium" type="primary" :loading="creatingJourney" @click="onCreateJourneyClick"> <i class="fa-regular fa-circle-plus pr-1"></i> Create Journey </el-button>
      </div>
    </div>

    <!-- Loader -->
    <div class="border rounded-md mt-2 overflow-hidden" v-if="fetchingData && journeyList" v-loading="true" style="min-height: 80vh"></div>

    <!-- Journey Data Container -->
    <div class="border rounded-md overflow-hidden w-full mt-3" v-if="!fetchingData">
      <!-- Status Tab -->
      <div class="journeyStatusTabs bg-white">
        <nav class="flex" aria-label="Tabs">
          <div v-for="(journeyStatus, index) in journeyStatusList" :key="index" class="tabItem border-transparent text-gray-700 hover:text-primary-700 text-center py-2 px-5 font-semibold" :class="{ selectedTab: selectedStatus == journeyStatus.value }" @click="onJourneyStatusFilterChange(journeyStatus.value)">
            <div class="mb-1 flex align-center justify-center">
              <div class="text-sm leading-4" style="margin-right: 6px">{{ journeyStatus.label }}</div>

              <!-- Status Count -->
              <div v-if="journeyStatus.value == 'all'" class="countTag bg-primary-500 text-white w-auto px-2 rounded-full">
                {{ journeyStatus.count }}
              </div>
              <span v-if="journeyStatus.value == 'running'" class="countTag bg-green-600 text-white w-auto px-2 rounded-full">
                {{ journeyStatus.count }}
              </span>
              <span v-if="journeyStatus.value == 'draft'" class="countTag bg-gray-700 text-white w-auto px-2 rounded-full">
                {{ journeyStatus.count }}
              </span>
              <span v-if="journeyStatus.value == 'ended'" class="countTag bg-red-600 text-white w-auto px-2 rounded-full">
                {{ journeyStatus.count }}
              </span>
              <span v-if="journeyStatus.value == 'suspended'" class="countTag bg-yellow-600 text-white w-auto px-2 rounded-full">
                {{ journeyStatus.count }}
              </span>
            </div>
          </div>
        </nav>
      </div>

      <!-- Journey List -->
      <template v-if="journeyList && journeyList.length > 0">
        <div class="journeyListTable overflow-hidden w-full border-t">
          <!-- Header -->
          <div class="journeyTableHeader flex py-0 pl-0 text-gray-500 bg-gray-50 text-sm font-semibold text-gray-900 text-left">
            <div class="flex-1 pl-3 journeyTableHeaderColumn">Journey Name</div>
            <div class="pl-3 journeyTableHeaderColumn" :style="{ width: tableColWidths.status }">Status</div>
            <div class="pl-3 journeyTableHeaderColumn" :style="{ width: tableColWidths.entries }">
              Entries
              <div class="analysisRange font-normal text-gray-500">
                <span @click="onAnalyticsDayRangeChange('24h')" class="cursor-pointer hover:text-primary-800" :class="{ selectedAnalysisRange: analysisTimeRange == '24h' }" style="margin-right: 1px">24h</span>
                <span @click="onAnalyticsDayRangeChange('7d')" class="cursor-pointer hover:text-primary-800" :class="{ selectedAnalysisRange: analysisTimeRange == '7d' }" style="margin-right: 1px">7d</span>
                <span @click="onAnalyticsDayRangeChange('30d')" class="cursor-pointer hover:text-primary-800" :class="{ selectedAnalysisRange: analysisTimeRange == '30d' }">30d</span>
              </div>
            </div>
            <div class="pl-3 journeyTableHeaderColumn" :style="{ width: tableColWidths.converted }">
              Conversions
              <div class="analysisRange font-normal text-gray-500">
                <span @click="onAnalyticsDayRangeChange('24h')" class="cursor-pointer hover:text-primary-800" :class="{ selectedAnalysisRange: analysisTimeRange == '24h' }" style="margin-right: 1px">24h</span>
                <span @click="onAnalyticsDayRangeChange('7d')" class="cursor-pointer hover:text-primary-800" :class="{ selectedAnalysisRange: analysisTimeRange == '7d' }" style="margin-right: 1px">7d</span>
                <span @click="onAnalyticsDayRangeChange('30d')" class="cursor-pointer hover:text-primary-800" :class="{ selectedAnalysisRange: analysisTimeRange == '30d' }">30d</span>
              </div>
            </div>
            <div class="pl-3 journeyTableHeaderColumn" :style="{ width: tableColWidths.revenue }">
              Revenue
              <div class="analysisRange font-normal text-gray-500">
                <span @click="onAnalyticsDayRangeChange('24h')" class="cursor-pointer hover:text-primary-800" :class="{ selectedAnalysisRange: analysisTimeRange == '24h' }" style="margin-right: 1px">24h</span>
                <span @click="onAnalyticsDayRangeChange('7d')" class="cursor-pointer hover:text-primary-800" :class="{ selectedAnalysisRange: analysisTimeRange == '7d' }" style="margin-right: 1px">7d</span>
                <span @click="onAnalyticsDayRangeChange('30d')" class="cursor-pointer hover:text-primary-800" :class="{ selectedAnalysisRange: analysisTimeRange == '30d' }">30d</span>
              </div>
            </div>
            <div class="pl-3 journeyTableHeaderColumn" :style="{ width: tableColWidths.lastUpdated }">Last Updated</div>
          </div>

          <!-- Journey Rows -->
          <div class="journeyTableRow flex py-2.5 pl-0 bg-white border-t" v-for="(journey, index) in journeyList" :key="index">
            <div class="flex-1 pl-3 pt-2.5 cursor-pointer break-words" style="font-size: 14px; line-height: 22px">
              <router-link class="text-black hover:text-black break-words" :to="'/journey/' + journey.id">
                {{ journey.name }}
              </router-link>
            </div>
            <div class="pl-3 pt-2.5 text-white" style="font-size: 10px" :style="{ width: tableColWidths.status }">
              <span v-if="journey.status == 'draft'" class="inline-flex items-center rounded-sm bg-gray-600 px-1.5 py-0.5">DRAFT</span>
              <span v-else-if="journey.status == 'running'" class="inline-flex items-center rounded-sm bg-green-600 px-1.5 py-0.5">RUNNING</span>
              <span v-else-if="journey.status == 'suspended'" class="inline-flex items-center rounded-sm bg-yellow-600 px-1.5 py-0.5">SUSPENDED</span>
              <span v-else-if="journey.status == 'ended'" class="inline-flex items-center rounded-sm bg-red-600 px-1.5 py-0.5">STOPPED</span>
            </div>
            <div class="pl-3 pt-2.5 text-sm" :style="{ width: tableColWidths.entries }">
              <template v-if="journey.status != 'draft'">
                <template v-if="journey.analysis">{{ getNumberWithComma(journey.analysis.entries) }}</template>
                <template v-else>
                  <beat-loader :loading="true" color="rgb(103, 121, 153)" size="6px"></beat-loader>
                </template>
              </template>
              <span v-else>&nbsp;</span>
            </div>
            <div class="pl-3 pt-2.5 text-sm" :style="{ width: tableColWidths.converted }">
              <template v-if="journey.status != 'draft'">
                <template v-if="journey.analysis">{{ getNumberWithComma(journey.analysis.converted) }}</template>
                <template v-else>
                  <beat-loader :loading="true" color="rgb(103, 121, 153)" size="6px"></beat-loader>
                </template>
              </template>
              <span v-else>&nbsp;</span>
            </div>
            <div class="pl-3 pt-2.5 text-sm" :style="{ width: tableColWidths.revenue }">
              <template v-if="journey.status != 'draft'">
                <template v-if="journey.analysis">{{ $store.state.token.currency }}{{ getNumberWithComma(journey.analysis.revenue) }}</template>
                <template v-else>
                  <beat-loader :loading="true" color="rgb(103, 121, 153)" size="6px"></beat-loader>
                </template>
              </template>
              <span v-else>&nbsp;</span>
            </div>
            <div class="pl-3 pt-2.5 text-xs text-gray-500" :style="{ width: tableColWidths.lastUpdated }">
              {{ journey.updated_at }} <br />
              By {{ journey.updator_name }}
            </div>
          </div>

          <!-- Journey Pagination -->
          <div class="flex pt-2 pb-2 bg-white px-3 border-t">
            <div class="pt-1 flex-1 text-gray-500 text-sm font-semibold text-gray-900 text-left">{{ pageOptions.total }} Journeys</div>
            <div class="-mt-1 rounded-md overlfow-hidden">
              <el-pagination size="small" @current-change="fetchJourneyList" :current-page.sync="pageOptions.pageNumber" :page-size="pageOptions.pageSize" layout="prev, pager, next" :total="pageOptions.total"></el-pagination>
            </div>
          </div>
        </div>
      </template>

      <!-- No Data -->
      <div class="noDataDiv" v-if="journeyList && journeyList.length == 0">
        <template v-if="selectedStatus == 'all'">
          <div class="msg">No Journeys Found</div>
          <router-link :to="{ path: '/journey/create' }">
            <el-button class="createJourneyButton" :loading="creatingJourney" type="primary" @click="onCreateJourneyClick"> Create New Journey </el-button>
          </router-link>

          <div class="journeyFeature">
            Visually build and deliver omnichannel campaigns based on your users’ behavior, location,
            <br />
            and lifecycle stage. Journey allows you to increase sales and engagement with customers.

            <el-row :gutter="30" class="featureList">
              <el-col :span="8">
                <i class="fa fa-comments"></i>
                <div class="title">Improve Customer Engagement</div>
                <div class="description">Deliver behavior-based, automated multi channel messages that keep customers engaged every step of the way.</div>
              </el-col>
              <el-col :span="8">
                <i class="fa fa-magic" aria-hidden="true"></i>
                <div class="title">Automate Precise Journeys</div>
                <div class="description">Target the right customer segments with beautiful, precise messages for one-off and ongoing journeys.</div>
              </el-col>
              <el-col :span="8">
                <i class="fa fa-line-chart" aria-hidden="true"></i>
                <div class="title">Understand What Drives Sales</div>
                <div class="description">Measure journey impact beyond opens and clicks to truly understand what initiatives drive sales.</div>
              </el-col>
            </el-row>
          </div>
        </template>
        <div class="text-gray-400" v-else style="min-height: 70vh; padding-top: 34vh">No Journeys Found.</div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" src="./journeyList.scss"></style>

<script>
import journeyListComponent from './journeyListComponent';
export default journeyListComponent;
</script>
