import { fetchStatusWiseCount, fetchJourneyList, fetchJourneyListAnalytics, createDraft } from '../../services/journey';
const moment = require('moment');
const _ = require('lodash');
import BeatLoader from '@/components/loaders/BeatLoader.vue';

export default {
  name: 'journeyList',
  components: {
    BeatLoader
  },
  props: {
    channelType: String,
    channel: String
  },
  data() {
    return {
      creatingJourney: false,
      fetchingData: false,
      journeyList: [],
      journeyIdList: [],
      journeyReportPageRoute: '',
      analysisTimeRange: '7d',

      selectedStatus: 'all',
      tableColWidths: {
        status: '110px',
        entries: '130px',
        converted: '130px',
        revenue: '130px',
        lastUpdated: '190px'
      },

      journeyStatusList: [
        { label: 'All', value: 'all', count: 100 },
        { label: 'Running', value: 'running', count: 100 },
        { label: 'Drafts', value: 'draft', count: 100 },
        { label: 'Stopped', value: 'ended', count: 100 },
        { label: 'Suspended', value: 'suspended', count: 100 }
      ],

      searchFilter: '',
      filterOptions: {
        status: [
          { label: 'Draft', value: 'draft' },
          { label: 'Running', value: 'running' },
          { label: 'Suspended', value: 'suspended' },
          { label: 'Stopped', value: 'ended' }
        ]
      },

      pageOptions: {
        pageSize: 10,
        pageNumber: 1,
        total: null
      }
    };
  },

  methods: {
    async onCreateJourneyClick() {
      let result = null;
      try {
        result = await this.$prompt('Journey Name', 'Create New Journey', {
          confirmButtonText: 'Create Journey',
          cancelButtonText: 'Cancel',
          inputValue: this.journeyName,
          inputPattern: /.{3,100}/,
          inputErrorMessage: 'Campaign name must be of 3-100 characters.'
        });
      } catch (err) {
        // Ignore cancel button click;
        return;
      }

      try {
        this.creatingJourney = true;

        let response = await createDraft({
          name: result.value
        });

        let journeyId = response.data;

        this.$router.push('/journey/' + journeyId);
      } catch (err) {
        this.creatingJourney = false;
        console.log('Failed to save journey name', err);
        this.errorToast('Failed to change journey name. Please contact support team.');
      }
    },

    onJourneyStatusFilterChange(newStatus) {
      this.selectedStatus = newStatus;
      this.resetPaginationAndSearch();
    },

    onAnalyticsDayRangeChange(dayRangeInput) {
      this.analysisTimeRange = dayRangeInput;
      this.fetchAnalyticsData();
    },

    getJourneyDetailsPageLink(journeyId) {
      return (this.journeyReportPageRoute = '/journey/' + journeyId);
    },

    onSearch: _.debounce(function () {
      this.resetPaginationAndSearch();
      this.populateStatusWiseCount();
    }, 700),

    resetPaginationAndSearch() {
      this.pageOptions.pageNumber = 1;
      this.fetchJourneyList();
    },

    fetchJourneyList(saveQueryInUrl = false) {
      this.fetchingData = true;

      let params = {
        search: this.searchFilter,
        status: this.selectedStatus,
        analysisTimeRange: this.analysisTimeRange,
        pageOptions: this.pageOptions
      };

      // Modify Query-String
      if (!saveQueryInUrl) {
        const query = Object.assign({}, this.$route.query);
        query.search = params.search;
        query.status = JSON.stringify(params.status);
        query.pageOptions = JSON.stringify(params.pageOptions);
        this.$router.push({ query });
      }

      fetchJourneyList(params, this)
        .then((result) => {
          this.journeyList = result.data.list;
          this.journeyIdList = result.data.idList;
          this.pageOptions.total = result.data.total;

          for (var i = 0; i < this.journeyList.length; i++) {
            this.journeyList[i].analysis = null;

            this.journeyList[i].created_at = this.journeyList[i].created_at && this.journeyList[i].created_at != 'N/A' ? this.getDisplayTime(this.journeyList[i].created_at) : 'N/A';
            this.journeyList[i].updated_at = this.journeyList[i].updated_at && this.journeyList[i].updated_at != 'N/A' ? this.getDisplayTime(this.journeyList[i].updated_at) : 'N/A';

            // Creator
            this.creatorName = null;
            if (this.journeyList[i].creator_first_name) {
              this.creatorName = this.journeyList[i].creator_first_name + ' ' + this.journeyList[i].creator_last_name;
            }
            this.journeyList[i].creator_name = this.creatorName;

            // Updator
            this.updatorName = null;
            if (this.journeyList[i].updator_first_name) {
              this.updatorName = this.journeyList[i].updator_first_name + ' ' + this.journeyList[i].updator_last_name;
            }
            this.journeyList[i].updator_name = this.updatorName;
          }

          requestAnimationFrame(() => {
            this.fetchAnalyticsData();
          });

          this.fetchingData = false;
        })
        .catch((e) => {
          this.reportError(e);
          this.errorToast('Failed to fetch journeys. Please contact support.');
          this.fetchingData = false;
        });
    },

    async fetchAnalyticsData() {
      try {
        // Show loader first.
        for (let i = 0; i < this.journeyList.length; i++) {
          this.journeyList[i].analysis = null;
        }

        let params = {
          analysisTimeRange: this.analysisTimeRange,
          journeyIdList: this.journeyIdList
        };

        let result = await fetchJourneyListAnalytics(params);

        for (let i = 0; i < this.journeyList.length; i++) {
          let journey = this.journeyList[i];
          let journeyAnalytics = result.data[journey.id];

          if (journeyAnalytics) {
            journey.analysis = {};
            journey.analysis.entries = journeyAnalytics.entries;
            journey.analysis.converted = journeyAnalytics.converted;
            journey.analysis.revenue = journeyAnalytics.revenue;
          }

          this.journeyList[i] = journey;
        }
        this.$forceUpdate();
      } catch (err) {
        // this.errorToast('Failed to fetch journey reports. Please contact support.');
        console.error('Failed to fetch analytics data.', err);
      }
    },

    async populateStatusWiseCount() {
      try {
        let result = await fetchStatusWiseCount({
          name: this.searchFilter
        });
        result = result.data;

        for (let i = 0; i < this.journeyStatusList.length; i++) {
          let status = this.journeyStatusList[i].value;
          if (result[status]) this.journeyStatusList[i].count = result[status];
          else this.journeyStatusList[i].count = 0;
        }

        console.log('Status Wise Count', result, this.journeyStatusList);
      } catch (err) {
        console.log('Failed to fetch status wise count.', err);
      }
    },

    getDisplayTime(date) {
      if (!date) {
        return `N/A`;
      }
      var dt = moment(date, 'x');
      return dt.format('DD-MMM-YYYY hh:mm A');
    }
  },

  mounted() {
    // Modify Query-String
    const query = this.$route.query;
    this.searchFilter = query.search ? query.search : this.searchFilter;
    this.status = query.status ? JSON.parse(query.status) : this.status;
    this.pageOptions = query.pageOptions ? JSON.parse(query.pageOptions) : this.pageOptions;

    this.fetchJourneyList(true);
    this.populateStatusWiseCount();
  }
};
